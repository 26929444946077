<template>
  <div class="communication">
    <div class="container">
      <div class="title-container">
        <p class="title">{{ $t('bae89f7') }}</p>
        <p class="more" @click="jumpCommunication">更多</p>
      </div>
      <div class="inner-container">
        <div class="lf card">
          <img :src="policyBg" alt="" class="bg" />
          <ul class="news">
            <li v-for="(item, key) in policyData" :key="key" @click="jumpPolicy(item.id)">
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="rf">
          <div v-for="(item, index) of communicationData" :key="index" class="item card">
            <figure>
              <img :src="imgArray[index]" alt="" class="bg" />
              <figcaption @click="jumpDetailByPath(index, item.id)">{{ item.title }}</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/contract/service'
export default {
  name: 'CommunicationCard',
  data() {
    return {
      imgArray: [
        require('@/assets/communication/home/news.png'),
        require('@/assets/communication/home/experts.png'),
        require('@/assets/communication/home/notice.png'),
        require('@/assets/communication/home/ask.png'),
      ],
      path: ['news', 'experts', 'notice', 'ask'],
      policyBg: require('@/assets/communication/home/policy.png'),
      policyData: [],
      communicationData: [],
    }
  },
  mounted() {
    this.getCommunicationData()
  },
  methods: {
    jumpCommunication() {
      this.$router.push({ path: '/communication' })
    },
    getCommunicationData() {
      API.getHomeData().then(res => {
        const { askAnswer, expertInterview, news, notice, policy } = res
        this.communicationData = [news, expertInterview, notice, askAnswer]
        this.policyData = policy
      })
    },
    jumpPolicy(id) {
      this.$router.push({
        path: `/communication/policy/detail?id=${id}&page=1&size=15`,
      })
    },
    jumpDetailByPath(index, id) {
      const path = this.path[index]
      this.$router.push({
        path: `/communication/${path}/detail?id=${id}&page=1&size=15`,
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.communication {
  width: 100%;
  height: 762px;
  background-color: #fff;
  .title {
    font-size: 28px;
    color: #333;
    line-height: 42px;
    position: relative;
    text-indent: 16px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 28px;
      background: #007AFFFF;
    }
  }
}
.container {
  width: @uni-width;
  height: 100%;
  margin: 0 auto;
}
.title-container {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.more {
  font-size: 16px;
  color: #666666FF;
  padding: 40px;
  margin: -40px;
  cursor: pointer;
}
.inner-container {
  display: flex;
  justify-content: space-between;
}
.bg {
  width: 380px;
  height: 232px;
  border-radius: 8px;
}
.card {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #E5E8ED;
  border-top: none;
  background-color: #fff;
  box-sizing: border-box;
}
.lf {
  width: 380px;
  height: 614px;
  margin-right: 30px;
}
.news {
  height: 382px;
  padding: 0 20px;
  font-size: 16px;
  li {
    height: 62px;
    line-height: 62px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:last-child {
      border-bottom: none;
    }
  }
}
.rf {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 614px;
  // align-items: stretch;
  .item {
    background-color: #fff;
    border-radius: 8px;
    width: 380px;
    height: 292px;
    &:first-child {
      margin-bottom: 30px;
    }
  }
  figcaption {
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
