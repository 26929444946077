<template>
  <div class="home">
    <Header />
    <Navigation />
    <NewsList />
    <div class="container">
      <Law :info="legalNeedsInfo" :law-data="needsData" />
      <Law :info="legalAidInfo" :law-data="aidData" class="legalAidInfo" />
    </div>
    <DataDisplay
      v-if="legalProduct.length"
      title="法律服务产品"
      :data="legalProduct"
      path="/lawServiceProduct/details"
      more="/lawServiceProduct/list"
    />
    <DataDisplay
      v-if="researchResult.length"
      title="法律研究成果"
      :data="researchResult"
      path="/researchResult/list"
      more="/researchResult/types"
      query-name="type"
    />
    <Communication />
    <Statistics :data="statistics" />
  </div>
</template>

<script>
import Header from './components/Header'
import Law from './components/Law'
import Navigation from './components/Navigation'
import NewsList from './components/NewsList'
import Statistics from './components/Statistics'
import ConditionSelection from '@/components/ConditionSelection'
import FirmCard from '@/components/FirmCard'
import ProfileCard from '@/components/ProfileCard'
import Communication from './components/Communication'
import DataDisplay from '@/views/home/components/DataDisplay.vue'
import api from '@/api/common'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    Law,
    Navigation,
    Statistics,
    ConditionSelection,
    FirmCard,
    ProfileCard,
    Communication,
    NewsList,
    DataDisplay,
  },
  data() {
    return {
      needsData: [],
      aidData: [],
      legalProduct: [],
      researchResult: [],
      imgList: [
        require('@/assets/researchResult/gjwl.png'),
        require('@/assets/researchResult/xdmy.png'),
        require('@/assets/researchResult/sxtz.png'),
        require('@/assets/researchResult/slwh.png'),
      ],
      legalNeedsInfo: {
        title: 'home.legalNeedsInfo.title',
        des: 'home.legalNeedsInfo.des',
        path: '/service/demand/details',
        buttomContent: '查看详情',
        img: require('@/assets/home/法律需求@2x.png'),
        lableList: [
          {
            lable: '发布单位',
            key: 'name',
          },
          {
            lable: '涉及区域',
            key: 'country',
          },
          {
            lable: '需求类型',
            key: 'type',
          },
          {
            lable: '需求内容',
            key: 'content',
          },
          {
            lable: '需求状态',
            key: 'urgentStatus',
          },
        ],
      },
      legalAidInfo: {
        title: 'home.legalAidInfo.title',
        des: 'home.legalAidInfo.des',
        path: '/service/desk/details',
        buttomContent: '服务详情',
        img: require('@/assets/home/法律服务@2x.png'),
        lableList: [
          { lable: '发布单位', key: 'createBy' },
          { lable: '涉及区域', key: 'country' },
          { lable: '业务类型', key: 'businessType' },
          { lable: '服务描述', key: 'content' },
          { lable: '服务量', key: 'num' },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['lawRequirementsObj', 'lawServiceObj', 'statistics']),
  },
  watch: {
    lawRequirementsObj(newVal) {
      if (newVal) {
        this.needsData = newVal.content
      }
    },
    lawServiceObj(newVal) {
      if (newVal) {
        this.aidData = newVal.content
      }
    },
  },
  created() {
    this.getLawRequirements({
      page: 1,
      size: 3,
    })
    this.getLawService({
      page: 1,
      size: 3,
    })
    this.getStatistics()
    this.getData()
  },
  methods: {
    ...mapActions(['getLawRequirements', 'getLawService', 'getStatistics']),
    getData() {
      api.getLegalResearchResult().then(res => {
        let data = res?.content || []
        data = data.map((item, index) => {
          return { ...item, logo: this.imgList[index], name: item.type, id: item.type }
        })
        this.researchResult = data
      })
      api.getLegalServiceProduct().then(res => {
        this.legalProduct = res?.content || []
      })
    },
    change() {
      this.$i18n.locale = 'zh'
    },
    change1() {
      this.$i18n.locale = 'en'
    },
    change2() {
      this.$i18n.locale = 'ru'
    }
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.home {
  width: 100%;
  .container {
    width: @uni-width;
    margin: 0 auto;
    padding: 60px 0;
    .legalAidInfo {
      margin-top: 20px;
    }
  }
}
</style>
