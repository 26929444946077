<template>
  <div class="navigation">
    <div class="container">
      <div class="item" @click="jumpDetails(0)">
        <img :src="getIcon(0)" alt="" class="uiImg" />
        <div class="name">{{ $t('home.navList')[0] }}</div>
        <div class="des">{{ $t('home.navListDes1')[0] }}</div>
        <div class="des">{{ $t('home.navListDes2')[0] }}</div>
      </div>
      <div class="line"></div>
      <div class="item" @click="jumpDetails(1)">
        <img :src="getIcon(1)" alt="" class="uiImg" />
        <div class="name">{{ $t('home.navList')[1] }}</div>
        <div class="des">{{ $t('home.navListDes1')[1] }}</div>
        <div class="des">{{ $t('home.navListDes2')[1] }}</div>
      </div>
      <div class="line"></div>
      <div class="item" @click="jumpDetails(2)">
        <img :src="getIcon(2)" alt="" class="uiImg" />
        <div class="name">{{ $t('home.navList')[2] }}</div>
        <div class="des">{{ $t('home.navListDes1')[2] }}</div>
        <div class="des">{{ $t('home.navListDes2')[2] }}</div>
      </div>
      <div class="line"></div>
      <div class="item" @click="jumpDetails(3)">
        <img :src="getIcon(3)" alt="" class="uiImg" />
        <div class="name">{{ $t('home.navList')[3] }}</div>
        <div class="des">{{ $t('home.navListDes1')[3] }}</div>
        <div class="des">{{ $t('home.navListDes2')[3] }}</div>
      </div>
      <div class="line"></div>
      <div class="item" @click="jumpDetails(4)">
        <img :src="getIcon(4)" alt="" class="uiImg" />
        <div class="name">{{ $t('home.navList')[4] }}</div>
        <div class="des">{{ $t('home.navListDes1')[4] }}</div>
        <div class="des">{{ $t('home.navListDes2')[4] }}</div>
      </div>
      <div class="line"></div>
      <div class="item" @click="jumpDetails(5)">
        <img :src="getIcon(5)" alt="" class="uiImg" />
        <div class="name">{{ $t('home.navList')[5] }}</div>
        <div class="des">{{ $t('home.navListDes1')[5] }}</div>
        <div class="des">{{ $t('home.navListDes2')[5] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      list: [
        {
          path: '/service/lawyer',
          icon: require('@/assets/home/navIcon1.png'),
        },
        {
          path: '/service/arbitration',
          icon: require('@/assets/home/navIcon6.png'),
        },
        {
          path: '/service/mediate',
          icon: require('@/assets/home/navIcon4.png'),
        },
        {
          path: '/service/notarization',
          icon: require('@/assets/home/navIcon3.png'),
        },
        {
          path: '/service/appraisal',
          icon: require('@/assets/home/navIcon5.png'),
        },
        {
          path: '/service/legalAid',
          icon: require('@/assets/home/navIcon2.png'),
        },
      ],
    }
  },
  methods: {
    getIcon(index) {
      return this.list[index].icon
    },
    jumpDetails(index) {
      const item = this.list[index]
      this.$router.push({
        path: item.path,
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.navigation {
  width: 100%;
  height: 272px;
  background: #fff;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: @uni-width;
    height: 100%;
    margin: 0 auto;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      cursor: pointer;
      .uiImg {
        width: 50px;
        height: 50px;
        color: @uni-text-color-grey;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
      .name {
        margin: 28px 0 16px 0;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
      .des {
        line-height: 21px;
      }
      // &::after {
      //   position: absolute;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   right: -58px;
      //   content: '';
      //   width: 2px;
      //   height: 98px;
      //   background: #dddddd;
      // }
    }
    .line {
      width: 2px;
      height: 98px;
      background: #dddddd;
    }
    // .lastChild::after {
    //   display: none;
    // }
  }
}
</style>
