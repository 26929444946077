<template>
  <div class="profile-card-box">
    <div
      class="profile-card-image"
      :style="{ backgroundImage: `url(${headShot})` }"
    ></div>
    <div class="profile-card-info">
      <h1>{{ name | textFilter }}</h1>
      <p>{{ firmName | textFilter }}</p>
      <p>执业年限：{{ yearOfWorking | textFilter }}</p>
      <p>执业证号：{{ licenceNum | textFilter }}</p>
    </div>
  </div>
</template>

<script>
import './index.less'

export default {
  name: 'ProfileCard',
  props: {
    name: String,
    headShot: String,
    firmName: String,
    yearOfWorking: String,
    licenceNum: String,
  },
  methods: {},
}
</script>
