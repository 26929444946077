<template>
  <div class="newsList">
    <div class="container">
      <img v-for="item of list" :key="item.path" :src="item.icon" alt="" class="item" @click="jumpDetails(item.path)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsList',
  data() {
    return {
      list: [
        {
          path: 'https://www.ctils.com/categories/7/articles',
          icon: require('@/assets/home/news.png'),
        },
        {
          path: 'https://www.ctils.com/categories/10/articles',
          icon: require('@/assets/home/economy.png'),
        },
        {
          path: 'https://www.ctils.com/events/list',
          icon: require('@/assets/home/trade.png'),
        },
      ],
    }
  },
  methods: {
    jumpDetails(path) {
      window.open(path)
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.newsList {
  width: 100%;
  height: 124px;
  background: #fff;
  padding-bottom: 32px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: @uni-width;
    height: 100%;
    margin: 0 auto;
    .item {
      cursor: pointer;
      width: 380px;
      height: 124px;
    }
  }
}
</style>
