<template>
  <div class="law">
    <div class="lf">
      <div style="display: block;position: absolute;z-index: 10">
        <div class="title">{{ $t(info.title) }}</div>
        <div class="des">{{ $t(info.des) }}</div>
      </div>
      <img :src="info.img" alt="" class="uiImg" />
    </div>
    <div class="rf">
      <div v-for="(item, index) of lawData" :key="index" class="item">
        <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          :content="item.title"
        >
          <div slot="reference" class="title">{{ item.title }}</div>
        </el-popover>
        <div class="des">
          <div
            v-for="(lableItem, lableIndex) of info.lableList"
            :key="lableIndex"
            class="uiText"
          >
            {{ lableItem.lable }}：
            <span :style="styleObj(lableItem, item)">{{
              handleItem(lableItem, item) | textFilter
            }}</span>
          </div>
        </div>
        <div class="buttom" @click="jumpDetails(item.id)">
          {{ info.buttomContent }}
        </div>
      </div>
      <div
        v-for="(item, index) of 3 - lawData.length"
        :key="index + 'noData'"
        class="item"
      >
        <NoData type="content" size="medium" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  TYPE as typeObj,
  urgentStatus as urgentStatusObj
} from '@/utils/constants'
import NoData from '@/components/NoData'
export default {
  name: 'Law',
  components: {
    NoData,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    lawData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showTag: false,
    }
  },
  methods: {
    styleObj(lableItem, item) {
      const key = lableItem.key
      const result = item[key]
      const obj = {
        EMERGENCY: 'rgba(245, 34, 45, 1)',
        NORMAL: 'rgba(81, 197, 24, 1)',
      }
      const color = obj[result]
      if (color) {
        return {
          color,
        }
      }
    },
    handleItem(lableItem, item) {
      const key = lableItem.key
      const result = item[key]
      return typeObj[result] || urgentStatusObj[result] || result
    },
    jumpDetails(id) {
      this.$router.push({
        path: this.info.path,
        query: {
          id,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.law {
  display: flex;
  width: @uni-width;
  background-image: url("~@/assets/home/BG@2x.png");
  padding: 30px 30px 30px 0;
  box-sizing: border-box;
  .lf {
    position: relative;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    box-sizing: border-box;
    width: 240px;
    height: 254px;
    padding: 40px 25px 0;
    //background: linear-gradient(180deg, #0673ee 0%, #009fff 99%);
    .title {
      //width: 232px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      z-index: 1;
    }

    .des {
      align-self: flex-start;
      margin-top: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      z-index: 1;
    }

    .uiImg {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .rf {
    display: flex;
    flex-wrap: wrap;
    width: 920px;
    //background: #ffffff;
    //border-top: 1px solid #dddddd;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      width: 30%;
      height: 254px;
      padding: 20px 23px;
      margin-left: 30px;
      border: 1px solid #dddddd;
      background: white;
      //border-top: none;
      //border-left: none;
      .title {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .tag {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        color: @uni-text-color-select;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          padding: 3px 8px;
          background: rgba(0, 164, 255, 0.07);
          border-radius: 2px;
        }
      }
      .des {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        .uiText {
          margin-bottom: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        div:last-child {
          margin-bottom: 0;
        }
      }
      .buttom {
        width: 180px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid @uni-text-color-select;
        font-size: 14px;
        font-weight: 400;
        color: #00a4ff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }

      .buttom:hover {
        height: 38px;
        color: #fff;
        background: linear-gradient(180deg, #05d0fe 0%, #2c6fe8 100%);
        border: none;
      }
    }
  }
}
</style>
