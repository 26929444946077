<template>
  <div class="data-display">
    <div class="content">
      <div class="title-container">
        <p class="title">{{ title }}</p>
        <p class="more" @click="handleMreBtnClick">更多</p>
      </div>
      <div class="item-container" >
        <div v-for="(item, index) of data" :key="index" class="item card" @click="jumpDetailByPath(item.id)">
          <figure>
            <img :src="item.logo" alt="" class="bg" />
            <figcaption>{{ item.name }}</figcaption>
          </figure>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    path: String,
    more: String,
    queryName: {
      type: String,
      default: 'id',
    },
    data: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      imgArray: [
        require('@/assets/communication/home/news.png'),
        require('@/assets/communication/home/experts.png'),
        require('@/assets/communication/home/notice.png'),
        require('@/assets/communication/home/ask.png'),
      ],
    }
  },
  methods: {
    jumpDetailByPath(id) {
      // const path = this.path[index]
      this.$router.push({
        path: `${this.path}?${this.queryName}=${id}`,
      })
    },
    handleMreBtnClick() {
      if (this.more) {
        this.$router.push({
          path: this.more,
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.data-display {
  //background: white;
  width: 100%;
  //height: 762px;
  background-color: #fff;
  .content {
    width: @uni-width;
    margin: 0 auto;
    .item-container {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      grid-column-gap: 20px;
      .item {
        cursor: pointer;
        padding: 18px;
        box-shadow: 0px 0px 35px 0px rgba(176,176,176,0.27);
        .bg {
          width: 100%;
          border-radius: 5px;
        }
        figcaption {
          text-align: center;
          margin-top: 12px;
          font-size: 20px;
        }
      }
    }
  }
}
.title-container {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more {
    font-size: 16px;
    color: #666666FF;
    //padding: 40px;
    //margin: -40px;
    cursor: pointer;
  }
  .title {
    font-size: 28px;
    color: #333;
    line-height: 42px;
    position: relative;
    text-indent: 16px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 28px;
      background: #007AFFFF;
    }
  }
}
</style>
