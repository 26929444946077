<template>
  <div class="header" :class="classObj">
    <div class="container">
      <!-- <div class="title">{{ $t('home.header.title') }}</div>
      <div class="des">{{ $t('home.header.des') }}</div> -->
      <div class="search">
        <div class="buttonContainer">
          <div
            v-for="(item, index) of $t(searchTypeData)"
            :key="index"
            :class="{ active: searchTypeIndex === index, uiText: index === 0 }"
            @click="changeSearchType(index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="content">
          <div class="country">
            <el-select
              v-model="country"
              :placeholder="$t('8c7aeca')"
              class="selectContainer"
            >
              <el-option
                v-for="item in dictionaryList.content"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </div>
          <input
            v-model="searchInput"
            class="searchInput"
            type="text"
            :placeholder="$t(inputPla)"
          />
          <div class="searchButtom" @click="search"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { debounce } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'HomeHeader',
  data() {
    return {
      searchInput: '',
      searchTypeIndex: 0,
      inputPla: '902c739',
      searchPath: '/legal/query',
      searchTypeData: 'home.header.searchTypeData',
      country: '',
      dictionaryListType: 'LAW_REGULATIONS',
      typeList: ['LAWS', 'CASE'],
    }
  },
  computed: {
    ...mapGetters(['dictionaryList']),
    classObj() {
      const locale = this.$i18n.locale
      return {
        en: locale === 'en',
        ru: locale === 'ru',
      }
    },
  },
  created() {
    this.getCountryList()
  },

  methods: {
    ...mapActions(['getDictionaryList']),
    getCountryList() {
      this.getDictionaryList({
        type: this.dictionaryListType,
      })
    },
    changeSearchType(index) {
      let pla = '902c739'
      this.dictionaryListType = 'LAW_REGULATIONS'
      if (index === 1) {
        pla = 'b65ea5a'
        this.dictionaryListType = 'JUDICIAL_CASE'
      }
      this.inputPla = pla
      this.searchTypeIndex = index
      this.searchInput = ''
      this.country = ''
      this.getCountryList()
    },
    search() {
      const searchKey = this.searchInput
      if (searchKey) {
        this.$router.push({
          path: this.searchPath,
          query: {
            country: this.country,
            keyword: this.searchInput,
            type: this.typeList[this.searchTypeIndex],
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.header {
  position: relative;
  min-width: @uni-width;
  // width: 100%;
  height: 520px;
  background: url('~@/assets/home/banner.png') center no-repeat;
  background-size: 100% 520px;
  .container {
    position: relative;
    top: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    align-items: center;
    width: @uni-width;
    height: 100%;
    margin: 0 auto;
    color: #ffffff;
    .title {
      margin-top: 80px;
      font-size: 78px;
      color: #ffffff;
      line-height: 101px;
      letter-spacing: 2px;
    }

    .des {
      margin-top: 11px;
      font-size: 30px;
      line-height: 52px;
      letter-spacing: 2px;
    }

    // .lang {
    //   display: flex;
    //   margin: 15px 0;
    //   .interval {
    //     position: relative;
    //     margin-right: 20px;
    //     &::after {
    //       position: absolute;
    //       right: -10px;
    //       top: 50%;
    //       transform: translateY(-50%);
    //       content: '';
    //       width: 1px;
    //       height: 10px;
    //       background: #fff;
    //     }
    //   }
    // }

    .search {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      width: @uni-width;
      height: 132px;
      margin: 280px 0 100px;
      padding: 20px 170px;
      background: rgba(0, 0, 0, 0.6);
      .buttonContainer {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        .uiText {
          margin-right: 20px;
        }
        div {
          padding: 10px;
          line-height: auto;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
        }
        .active {
          position: relative;
          background: @uni-text-color-select;
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -15px;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-bottom-color: #fff;
          }
        }
      }
      .content {
        display: flex;
        width: 100%;
        margin-top: 15px;
        .country {
          .selectContainer {
            & /deep/ .el-input__inner {
              height: 48px;
              border: none;
              border-right: 1px solid #ddd;
              border-radius: 4px 0 0 4px;
            }
          }
        }
        .searchInput {
          .uni-input;
          width: 641px;
          border-radius: 0;
        }
        .searchButtom {
          position: relative;
          width: 118px;
          height: 48px;
          background: @uni-text-color-select;
          border-radius: 0px 4px 4px 0px;
          cursor: pointer;
          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            width: 22px;
            height: 22px;
            background: url('~@/assets/home/searchIcon.png') no-repeat center;
            background-size: 22px 22px;
          }
        }
      }
    }
  }
}
.en {
  background: url('~@/assets/home/bannerEn.png') center no-repeat;
  background-size: 100% 520px;
}
.ru {
  background: url('~@/assets/home/bannerRu.png') center no-repeat;
  background-size: 100% 520px;
}
</style>

