import { render, staticRenderFns } from "./DataDisplay.vue?vue&type=template&id=aec9ce14&scoped=true&"
import script from "./DataDisplay.vue?vue&type=script&lang=js&"
export * from "./DataDisplay.vue?vue&type=script&lang=js&"
import style0 from "./DataDisplay.vue?vue&type=style&index=0&id=aec9ce14&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aec9ce14",
  null
  
)

export default component.exports