<template>
  <div class="navigation">
    <!--    <div class="container">
          <div class="item">
            <img :src="list[0]" alt="" class="uiImg" />
            <div class="des">{{ $t('home.des1List')[0] }}</div>
            <div>{{ $t('home.des2List')[0] }}</div>
          </div>
          <div class="line"></div>
          <div class="item">
            <img :src="list[1]" alt="" class="uiImg" />
            <div class="des">{{ $t('home.des1List')[1] }}</div>
            <div>{{ $t('home.des2List')[1] }}</div>
          </div>
          <div class="line"></div>
          <div class="item">
            <img :src="list[2]" alt="" class="uiImg" />
            <div class="des">{{ $t('home.des1List')[2] }}</div>
            <div>{{ $t('home.des2List')[2] }}</div>
          </div>
          <div class="line"></div>
          <div class="item">
            <img :src="list[3]" alt="" class="uiImg" />
            <div class="des">{{ $t('home.des1List')[3] }}</div>
            <div>{{ $t('home.des2List')[3] }}</div>
          </div>
          <div class="line"></div>
          <div class="item">
            <img :src="list[4]" alt="" class="uiImg" />
            <div class="des">{{ $t('home.des1List')[4] }}</div>
            <div>{{ $t('home.des2List')[4] }}</div>
          </div>
        </div>-->
    <div class="container">
      <div class="title-container">
        <p class="title">友情链接</p>
      </div>
      <div class="inner-container">
        <div class="link-section">
          <a v-for="(link, index) in linkList" :key="index" target="_blank" :href="link.link" rel="noreferrer">{{ link.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Statistics',
  data() {
    return {
      list: [
        require('@/assets/home/staIcon1.png'),
        require('@/assets/home/staIcon2.png'),
        require('@/assets/home/staIcon3.png'),
        require('@/assets/home/staIcon4.png'),
        require('@/assets/home/staIcon5.png'),
      ],
      linkList: [
        { name: '上海合作组织地方经贸合作综合服务平台', link: 'https://scocsp.com/' },
        { name: '中华人民共和国司法部', link: 'http://www.moj.gov.cn/' },
        { name: '中国一带一路网', link: 'https://www.yidaiyilu.gov.cn/index.htm' },
        { name: '上海合作组织秘书处', link: 'http://chn.sectsco.org/' },
        { name: '上合示范区官网', link: 'http://www.scoda.com//' },
        { name: '中国贸促会企业跨境贸易投资法律综合支援平台', link: 'https://www.ctils.com/' },
      ]
    }
  },
  methods: {},
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';

.navigation {
  width: 100%;
  height: 272px;

  .container {
    align-items: center;
    width: @uni-width;
    height: 100%;
    margin: 0 auto;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .uiImg {
        width: 54px;
        height: 48px;
        // color: @uni-text-color-grey;
        // font-size: 14px;
        // font-weight: 400;
        // color: #666666;
      }

      .des {
        margin: 28px 0 26px 0;
        font-size: 32px;
        font-weight: bold;
        color: #333333;
      }

      // &::after {
      //   position: absolute;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   right: -25%;
      //   content: '';
      //   width: 2px;
      //   height: 98px;
      //   background: #dddddd;
      // }
    }

    .lastChild::after {
      display: none;
    }

    .line {
      width: 2px;
      height: 98px;
      background: #dddddd;
    }
  }
}

.link-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 36px;
  flex-shrink: 0;
  flex-wrap: wrap;
  a {
    display: inline-block;
    height: 36px;
    width: 30%;
    border: 1px solid #00a4ff;
    text-align: center;
    margin-bottom: 18px;
    border-radius: 4px;
    color: #00a4ff;
  }
}

.navigation {
  width: 100%;
  background-color: #fff;

  .title {
    font-size: 28px;
    color: #333;
    line-height: 42px;
    position: relative;
    text-indent: 16px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 28px;
      background: #007AFFFF;
    }
  }
}

.container {
  width: @uni-width;
  height: 100%;
  margin: 0 auto;
}

.title-container {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more {
  font-size: 16px;
  color: #666666FF;
  padding: 40px;
  margin: -40px;
  cursor: pointer;
}

.inner-container {
  display: flex;
  justify-content: space-between;
}
</style>
